/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import React, { useCallback, useState, useEffect } from 'react';

import axios from 'axios';

import { RadioList, ComplexSelect, BasicIcon, BasicButton } from '@nuage/ui-components';
import GroupCalculator from '@molecules/group/groupcalculator';
import { dispatchGtagEvent } from '@utils/eventsManager';

import lang from '@utils/lang';

// Styleguide components

import './calculator.scss';

const Calculator = () => {
    const { _string } = lang;

    const [totalPrice, setTotalPrice] = useState(0);
    const [totalPriceOff, setTotalPriceOff] = useState(0);

    const [images, setImages] = useState([]);
    const [system, setSystem] = useState('');
    const [systemV, setSystemV] = useState({
        value: '',
        label: '',
        favorite: false,
    });

    const [selectedCore, setSelectedCore] = useState('0');
    const [selectedRam, setSelectedRam] = useState('0');
    const [selectedDisk, setSelectedDisk] = useState('0');
    const [, setIp] = useState(false);

    const [items, setItems] = useState([]);
    const [flavorArray, setFlavorArray] = useState([]);
    const [fields, setFields] = useState({});
    const [enrichedFlavors, setEnrichedFlavors] = useState([]);

    const [loading, setLoading] = useState(true);
    const [listOsNode, setListOsNode] = useState(null);

    const [displayedFields] = useState(['core', 'ram', 'disk']);
    const [displayedPrice, setDisplayedPrice] = useState('uptime');

    const mapSet = {
        system: setSystem,
        systemV: setSystemV,
        core: setSelectedCore,
        ram: setSelectedRam,
        disk: setSelectedDisk,
        publicIp: setIp,
    };

    // Check if this combo is possible (return true or false and the price :) )
    const checkCompatibility = ({ core, ram, disk }) => {
        const findFlavor = enrichedFlavors.find(
            (flavor) => (
                flavor.ressources.core === core
                && flavor.ressources.ram === ram
                && flavor.ressources.disk === disk
            ),
        );
        return {
            exist: !!findFlavor,
            priceOn: findFlavor?.priceOn,
            priceOff: findFlavor?.priceOff,
        };
    };

    // Get all values for the chosen element. Return what is in and was'nt
    const getFieldsFromElt = useCallback((fieldsElts, flavors, type, value) => {
        const nValue = Number(value);

        const updatedFields = {};

        displayedFields.forEach((field) => {
            updatedFields[field] = [];
            fieldsElts[field].map((obj) => {
                updatedFields[field].push({
                    ...obj,
                    transparent: true,
                    id: `${field}-${obj.val}`,
                    label: `${obj.val}${field !== 'core' ? ` ${_string(`config_${field}_unit`)}` : ''}`.trim(),
                });
                return obj;
            });
        });

        for (let i = 0; i < flavors.length; i += 1) {
            const flavor = flavors[i];

            const coreI = updatedFields.core.findIndex(
                (singleCore) => singleCore.val === flavor.ressources.core,
            );
            const ramI = updatedFields.ram.findIndex((ram) => ram.val === flavor.ressources.ram);
            const diskI = updatedFields.disk.findIndex(
                (singleDisk) => singleDisk.val === flavor.ressources.disk,
            );

            if (flavor.ressources[type] === nValue) {
                updatedFields.core[coreI].transparent = false;
                updatedFields.ram[ramI].transparent = false;
                updatedFields.disk[diskI].transparent = false;
            }
        }

        return updatedFields;
    }, [_string, displayedFields]);

    const getSmallestFlavorFromSelect = (array, type, value) => {
        let orderTreatment = [];
        const number = Number(value);
        switch (type) {
        case 'core':
            orderTreatment = [
                'core',
                'ram',
                'disk',
            ];
            break;

        case 'ram':
            orderTreatment = [
                'ram',
                'disk',
                'core',
            ];
            break;

        case 'disk':
            orderTreatment = [
                'disk',
                'core',
                'ram',
            ];
            break;
        default: break;
        }
        const res = array.sort(
            (a, b) => a.ressources[orderTreatment[0]] - b.ressources[orderTreatment[0]]
            || a.ressources[orderTreatment[1]] - b.ressources[orderTreatment[1]]
            || a.ressources[orderTreatment[2]] - b.ressources[orderTreatment[2]],
        );

        return res.find((elt) => elt.ressources[orderTreatment[0]] === number);
    };

    const populateFlavors = useCallback((baseFlavors, baseItems) => {
        const completeFlavors = [];
        const populatedFields = {
            core: [],
            ram: [],
            disk: [],
        };

        for (let i = 0; i < baseFlavors.length; i += 1) {
            const baseFlavor = baseFlavors[i];
            const correspondingItem = baseItems.find(
                (item) => item.id === baseFlavor.item.externalId,
            );

            if (correspondingItem) {
                const { core, ram, disk } = correspondingItem;

                displayedFields.forEach((field) => {
                    if (!populatedFields[field].find(
                        (elt) => elt.val === correspondingItem[field],
                    )) {
                        populatedFields[field].push(
                            { val: correspondingItem[field], transparent: true },
                        );
                    }
                });

                const completeFlavor = {
                    // TODO : Detect EUR or DOLLAR based on _string
                    priceOn: baseFlavor.prices[0].valueOn,
                    priceOff: baseFlavor.prices[0].valueOff,
                    ressources: {
                        core,
                        disk,
                        ram,
                    },
                };
                completeFlavors.push(completeFlavor);
            }
        }
        populatedFields.core.sort((a, b) => a.val - b.val);
        populatedFields.ram.sort((a, b) => a.val - b.val);
        populatedFields.disk.sort((a, b) => a.val - b.val);

        const firstFlavor = getSmallestFlavorFromSelect(completeFlavors, 'core', populatedFields.core[0].val);
        setSelectedCore(`core-${firstFlavor.ressources.core}`);
        setSelectedRam(`ram-${firstFlavor.ressources.ram}`);
        setSelectedDisk(`disk-${firstFlavor.ressources.disk}`);

        setTotalPrice(firstFlavor.priceOn);
        setTotalPriceOff(firstFlavor.priceOff);

        return {
            completeFlavors,
            newFields: getFieldsFromElt(populatedFields, completeFlavors, 'core', populatedFields.core[0].val),
        };
    }, [displayedFields, getFieldsFromElt]);

    const onMouseHover = (res) => {
        // To determine what changed, we assumes based on the value send
        // Before the dash -> the type
        // After the dash -> the value
        const [type, value] = res.split('-');

        if (displayedFields.includes(type)) {
            const newFields = getFieldsFromElt(fields, enrichedFlavors, type, value);

            setFields(newFields);
        }
    };

    const resetHover = useCallback(() => {
        const [type, value] = selectedCore.split('-');
        const newFields = getFieldsFromElt(fields, enrichedFlavors, type, value);
        setFields(newFields);
    }, [enrichedFlavors, fields, getFieldsFromElt, selectedCore]);

    const handleClick = (res) => {
        // To determine what changed, we assumes based on the value send
        // Before the dash -> the type
        // After the dash -> the value
        const [type, value] = res.split('-');

        // Deal with the changing css
        const valCheck = {
            core: Number(selectedCore.split('-')[1]),
            ram: Number(selectedRam.split('-')[1]),
            disk: Number(selectedDisk.split('-')[1]),
        };

        valCheck[type] = Number(value);

        const actualFlavor = checkCompatibility(valCheck);

        const newFields = getFieldsFromElt(fields, enrichedFlavors, type, value);
        setFields(newFields);

        if (!actualFlavor.exist) {
            const newFlavor = getSmallestFlavorFromSelect(enrichedFlavors, type, value);
            setSelectedCore(`core-${newFlavor.ressources.core}`);
            setSelectedRam(`ram-${newFlavor.ressources.ram}`);
            setSelectedDisk(`disk-${newFlavor.ressources.disk}`);
            setTotalPrice(newFlavor.priceOn);
            setTotalPriceOff(newFlavor.priceOff);
        } else {
            mapSet[type](res);
            setTotalPrice(actualFlavor.priceOn);
            setTotalPriceOff(actualFlavor.priceOff);
        }

        dispatchGtagEvent('interact', {
            event_category: 'component',
            event_label: 'simulateur tarifaire',
        });
    };

    const aggregateImages = (sendImages) => {
        if (!sendImages) { return false; }

        const aggregatedImages = [];

        for (let i = 0; i < sendImages.length; i += 1) {
            const image = sendImages[i];
            let objImage = aggregatedImages.find((elt) => elt.id === image.osName);
            if (!objImage) {
                objImage = {
                    id: image.osName,
                    name: image.description.replace(image.osVersion, '').trim(),
                    values: [{
                        value: image.osVersion,
                        label: image.osVersion,
                        isDefault: image.isDefault,
                    }],
                };
                aggregatedImages.push(objImage);
            } else if (!objImage.values.find((img) => img.value === image.osVersion)) {
                objImage.values.push({
                    value: image.osVersion,
                    label: image.osVersion,
                    isDefault: image.isDefault,
                });
            }
        }

        return aggregatedImages;
    };

    useEffect(() => {
        axios.get(`${process.env.GATSBY_API_URL}/catalog/products?item.type=flavor`)
            .then((response) => {
            // handle success
                setFlavorArray(response.data['hydra:member']);
            });

        axios.get(`${process.env.GATSBY_API_URL}/rockefeller/flavors`)
            .then((response) => {
            // handle success
                setItems(response.data['hydra:member']);
            });

        axios.get(`${process.env.GATSBY_API_URL}/rockefeller/images?order[osReleasedAt]=desc`)
            .then((response) => {
                const aImages = aggregateImages(response.data['hydra:member']).sort(( a, b ) => {
                    if (a.name.toLowerCase() < b.name.toLowerCase()) {
                        return -1;
                    }
                    if (a.name.toLowerCase() > b.name.toLowerCase()) {
                        return 1;
                    }
                    return 0;
                });
                if (aImages) {
                    const [firstImage] = aImages;

                    setImages(aImages);
                    setSystem(firstImage.id);
                    setSystemV(firstImage.values.find((val) => val.isDefault)
                        || firstImage.values[0]);
                }
            });
    }, []);

    useEffect(() => {
        if (!items) { return; }
        if (items.length > 0 && flavorArray.length > 0) {
            const { completeFlavors, newFields } = populateFlavors(flavorArray, items);
            setFields(newFields);
            setEnrichedFlavors(completeFlavors);
            setLoading(false);
        }
    }, [items, flavorArray, populateFlavors]);

    const stringPriceHour = (`${Math.round(totalPrice * 100000) / 100000} ${_string('currency')}`).replace(/\./g, ',');
    const stringPriceMonth = (`${(Math.round(totalPrice * 730 * 100) / 100).toFixed(2)} ${_string('currency')}`).replace(/\./g, ',');

    const stringPriceHourOff = (`${Math.round(totalPriceOff * 100000) / 100000} ${_string('currency')}`).replace(/\./g, ',');
    const stringPriceMonthOff = (`${(Math.round(totalPriceOff * 730 * 100) / 100).toFixed(2)} ${_string('currency')}`).replace(/\./g, ',');

    useEffect(() => {
        if (!images || images.length === 0) { return; }
        const listElt = [];
        for (let i = 0; i < images.length; i += 1) {
            const os = images[i];
            listElt.push({
                id: os.id,
                label: (
                    <>
                        <div className="complexLabelTitle">
                            <BasicIcon name={`ico-${os.id}`} color="auto" />
                            <p>{os.name}</p>
                        </div>
                        <div className="complexValue">
                            <ComplexSelect
                                name={`os-choice-${os.name}`}
                                values={os.values}
                                size="small"
                                loading={false}
                                onClick={(selectedOption) => {
                                    setSystemV(selectedOption);
                                    setSystem(os.id);
                                }}
                                selectedValue={
                                    system === os.id
                                        ? systemV
                                        : (os.values.find((val) => val.isDefault) || os.values[0])
                                }
                            />
                        </div>
                    </>
                ),
            });
        }
        setListOsNode(listElt);
    }, [systemV, system, images]);

    return (
        <div className="calculator">
            <div className="calculator__form">
                <GroupCalculator
                    name="system"
                    text={_string('config_os_title')}
                    icon="os"
                >
                    <RadioList
                        id="system"
                        name="system"
                        className="calculator__oss"
                        values={listOsNode}
                        onClick={(val) => {
                            if (val) {
                                setSystem(val);
                                setSystemV(images.find((os) => os.id === val)
                                    .values.find((selectedVal) => selectedVal.isDefault)
                                    || images.find((os) => os.id === val).values[0]);
                            }
                        }}
                        selectedValue={system}
                    />
                </GroupCalculator>
                <div
                    className="calculator__params"
                    onMouseLeave={resetHover}
                >
                    <GroupCalculator
                        name="core"
                        text={_string('config_core_title')}
                        icon="core"
                    >
                        <RadioList
                            id="core"
                            name="core"
                            values={loading ? [] : fields.core}
                            onClick={handleClick}
                            onMouseEnter={onMouseHover}
                            selectedValue={selectedCore}
                        />
                    </GroupCalculator>
                    <GroupCalculator
                        name="ram"
                        text={_string('config_ram_title')}
                        icon="ram"
                    >
                        <RadioList
                            id="ram"
                            name="ram"
                            values={loading ? [] : fields.ram}
                            onClick={handleClick}
                            onMouseEnter={onMouseHover}
                            selectedValue={selectedRam}
                        />
                    </GroupCalculator>
                    <GroupCalculator
                        name="disk"
                        text={_string('config_disk_title')}
                        icon="disk"
                    >
                        <RadioList
                            id="disk"
                            name="disk"
                            values={loading ? [] : fields.disk}
                            onClick={handleClick}
                            onMouseEnter={onMouseHover}
                            selectedValue={selectedDisk}
                        />
                    </GroupCalculator>
                </div>
                <ul className="calculator__infos">
                    <li className="calculator__infos__elt">
                        <BasicIcon name="ico-ip" />
                        <p className="calculator__infos__text">
                            {_string('prices_info1_text')}
                        </p>
                    </li>
                    <li className="calculator__infos__elt">
                        <BasicIcon name="ico-data" />
                        <p className="calculator__infos__text">
                            {_string('prices_info2_text')}
                        </p>
                    </li>
                    <li className="calculator__infos__elt">
                        <BasicIcon name="ico-transfer" />
                        <p className="calculator__infos__text">
                            {_string('prices_info3_text')}
                        </p>
                    </li>
                </ul>
            </div>
            <div className={`calculator__footer calculator__footer--${displayedPrice}`}>
                <div className="calculator__footer__buttons">
                    <BasicButton
                        className="calculator__footer__button calculator__footer__button--uptime"
                        color="white"
                        format="text"
                        leftIcon="ico-day"
                        onClick={() => { setDisplayedPrice('uptime'); }}
                    >
                        {_string('prices_uptime')}
                        <span className="calculator__footer__underline" />
                    </BasicButton>
                    <hr className="calculator__footer__separator" />
                    <BasicButton
                        className="calculator__footer__button calculator__footer__button--downtime"
                        color="white"
                        format="text"
                        leftIcon="ico-night"
                        onClick={() => { setDisplayedPrice('downtime'); }}
                    >
                        {_string('prices_downtime')}
                        <span className="calculator__footer__underline" />
                    </BasicButton>
                </div>
                <hr className="calculator__footer__main-separator" />
                <div className="calculator__footer__text">
                    <div className="calculator__footer__uptime">
                        <p className="calculator__footer__main">
                            {_string('prices_hour1')}
                            <span className="calculator__footer__main__price">{stringPriceHour}</span>
                            {_string('prices_hour2')}
                        </p>
                        <p className="calculator__footer__second">
                            {_string('prices_month1')}
                            <span className="calculator__footer__second__price">{stringPriceMonth}</span>
                            {_string('prices_month2')}
                        </p>
                    </div>
                    <div className="calculator__footer__downtime">
                        <p className="calculator__footer__main">
                            {_string('prices_hour1')}
                            <span className="calculator__footer__main__price">{stringPriceHourOff}</span>
                            {_string('prices_hour2')}
                        </p>
                        <p className="calculator__footer__second">
                            {_string('prices_month1')}
                            <span className="calculator__footer__second__price">{stringPriceMonthOff}</span>
                            {_string('prices_month2')}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

Calculator.propTypes = {
};

export default Calculator;
