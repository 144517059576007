import React, { useState, useEffect } from 'react';

import Banner from '@images/svg/banner-tarifs.svg';
import Boxes from '@images/svg/boxes.svg';

import { BasicButton, A } from '@nuage/ui-components';

import AlsoIncluded from '@molecules/group/alsoIncluded';

import MainLayout from '@organisms/main/mainLayout';
import Header from '@organisms/main/header';
import Footer from '@organisms/main/footer';
import Part from '@organisms/main/part';
import Calculator from '@organisms/forms/calculator';

import { dispatchGtagEvent } from '@utils/eventsManager';
import lang from '@utils/lang';
import { usePageContext } from '@organisms/contexts/pageContext';

import downloadFile from '../../static/downloads/Tarifs Nua.ge 2022-07-28.pdf';

import './tarifs.scss';

const SecondPage = () => {
    const { _string } = lang;
    const { setPage } = usePageContext();

    const [binary, setBinary] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const [subListOpen, setSubListOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const toggleSubList = (e) => {
        e.preventDefault();
        setSubListOpen(!subListOpen);
    };

    // This is used for force re-render
    // TODO : Is there a better method to update first part when displayed on screen ?
    // (with react-intersection-observer and state, there is a flicker)
    useEffect(() => {
        if (!window) { return; }
        window.addEventListener('scroll', () => {
            setBinary((prev) => !prev);
        });
    }, []);

    useEffect(() => {
        if (!setPage) { return; }
        setPage('tarifs');
    }, [setPage]);

    return (
        <MainLayout
            classTitle="pricing"
            footer={false}
            socials={_string('prices_socials')}
            title={_string('prices_titleTab')}
        >
            <div className="primary_block">
                <div className="slidable">
                    <Header
                        menuOpen={menuOpen}
                        toggleMenu={toggleMenu}
                        toggleSubList={toggleSubList}
                        setSubListOpen={setSubListOpen}
                        isSubListOpen={subListOpen}
                        style={{ zIndex: `${binary ? '3' : '4'}` }}
                    />
                </div>

                <div className="primary_block__content">
                    <div className="primary_block__text">

                        <h1 className="primary_block__title">{_string('prices_title')}</h1>
                        <p className="primary_block__description"><strong>{_string('prices_description')}</strong></p>
                    </div>
                    <div className="primary_block__content__illu">
                        <Banner
                            className="primary_block__content__illu__main"
                        />
                    </div>
                </div>
            </div>

            <div className="secondary_block">
                <div className="slidable">
                    <Header
                        color="white"
                        menuOpen={menuOpen}
                        toggleMenu={toggleMenu}
                        toggleSubList={toggleSubList}
                        isSubListOpen={subListOpen}
                        setSubListOpen={setSubListOpen}
                    />
                </div>

                <Part
                    title={_string('prices_catchphrase')}
                    desc={_string('prices_description2')}
                    className="secondary_block__content"
                    separator={false}
                >
                    <A
                        linkProps={{
                            href: downloadFile,
                            download: 'Tarifs Nua.ge 2022-07-28.pdf',
                        }}
                        className="prices-download"
                    >
                        <BasicButton
                            onClick={() => {
                                window.location.href = _string('prices_link');
                                dispatchGtagEvent('file_download', {
                                    event_category: 'cta',
                                    event_label: 'grille tarifaire',
                                });
                            }}
                            color="secondary"
                            className="secondary_block__button secondary_block__button-mobile"
                        >
                            {_string('prices_cta')}
                        </BasicButton>
                    </A>

                    <div className="secondary_block__calculator">
                        <div className="secondary_block__calculator__content">
                            <Calculator />
                        </div>
                    </div>
                </Part>
                <AlsoIncluded />
                <Part
                    className="secondary_block__frame"
                >
                    <div className="secondary_block__frame__display">
                        <div className="secondary_block__frame__display__content">
                            <div className="secondary_block__frame__display__content__left">
                                <p className="secondary_block__frame__display__content__left__text">{_string('prices_description3')}</p>
                                <A
                                    linkProps={{
                                        href: downloadFile,
                                        download: 'Tarifs Nua.ge 2022-07-28.pdf',
                                    }}
                                    className="prices-download"
                                >
                                    <BasicButton
                                        color="secondary"
                                        className="secondary_block__frame__display__content__left__button"
                                        onClick={() => {
                                            window.location.href = _string('prices_link');
                                            dispatchGtagEvent('file_download', {
                                                event_category: 'cta',
                                                event_label: 'grille tarifaire',
                                            });
                                        }}
                                        size="large"
                                    >
                                        {_string('prices_cta')}
                                    </BasicButton>
                                </A>
                            </div>
                            <div className="secondary_block__frame__display__content__right">
                                <Boxes
                                    className="boxes"
                                />
                            </div>
                        </div>
                    </div>
                </Part>
                <Footer />
            </div>
        </MainLayout>
    );
};

export default SecondPage;
