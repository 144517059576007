import React from 'react';

import Api from '@images/svg/icon-api.svg';
import Commu from '@images/svg/icon-commu.svg';
import Content from '@images/svg/icon-content.svg';
import Plaisir from '@images/svg/icon-plaisir.svg';
import Support from '@images/svg/icon-support.svg';

import Part from '@organisms/main/part';

import lang from '@utils/lang';

import './alsoIncluded.scss';

const AlsoIncluded = () => {
    const { _string } = lang;

    return (
        <div className="included">
            <Part
                separator={false}
                title={_string('prices_subtitle')}
            >
                <ul className="included__list">
                    <li className="included__elt">
                        <div className="included__elt-content">
                            <Api className="included__icon" />
                            <p className="included__text">{_string('prices_cat1')}</p>
                        </div>
                    </li>
                    <li className="included__elt">
                        <div className="included__elt-content">
                            <Support className="included__icon" />
                            <p className="included__text">{_string('prices_cat2')}</p>
                        </div>
                    </li>
                    <li className="included__elt">
                        <div className="included__elt-content">
                            <Commu className="included__icon" />
                            <p className="included__text">{_string('prices_cat3')}</p>
                        </div>
                    </li>
                    <li className="included__elt">
                        <div className="included__elt-content">
                            <Content className="included__icon" />
                            <p className="included__text-col2">{_string('prices_cat4')}</p>
                        </div>
                    </li>
                    <li className="included__elt">
                        <div className="included__elt-content">
                            <Plaisir className="included__icon" />
                            <p className="included__text-col2">{_string('prices_cat5')}</p>
                        </div>
                    </li>
                </ul>
            </Part>
        </div>
    );
};
export default AlsoIncluded;
