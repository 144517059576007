import React from 'react';
import PropTypes from 'prop-types';

import './groupcalculator.scss';

const GroupCalculator = ({ name, icon, text, children }) => {
    // TODO : Find a better solution ?
    // eslint-disable-next-line import/no-dynamic-require, global-require
    const Logo = require(`@images/svg/config/icon-${icon}.svg`);

    return (
        <div className={`calculator__group calculator__${name}`}>
            <div className="calculator__name">
                <Logo className="calculator__icon" />
                <p className="calculator__title">{text}</p>
            </div>
            <div className="calculator__content">
                {children}
            </div>
        </div>
    );
};

GroupCalculator.propTypes = {
    children: PropTypes.node.isRequired,
    name: PropTypes.string.isRequired,
    text: PropTypes.string,
    icon: PropTypes.string.isRequired,
};

GroupCalculator.defaultProps = {
    text: null,
};

export default GroupCalculator;
